@let collapseMenu = this.isCollapsed();

<!-- Prefetch for our API -->
<link rel="preconnect" [href]="apiUrl" />

<div class="layout" [class.layout--collapsed]="collapseMenu">
  <div class="layout__menu">
    @if(!collapseMenu) {
    <div class="flex pt-4 pb-6 px-5 flex-shrink-0">
      <img class="w-12" src="../assets/lumatrak_pulse_white_logo.png" alt="" />
    </div>
    } @else {
    <div class="flex pt-4 pb-6 px-3 flex-shrink-0">
      <img class="w-12" src="../assets/lumatrak_circle_closed_white_logo.png" alt="" />
    </div>
    }

    <div class="overflow-y-auto">
      <ul class="list-none p-3 m-0">
        <li>
          <ul class="list-none p-0 m-0 overflow-hidden">
            @for(item of menuItems(); track item) {
            <li>
              <p-button
                (click)="menuClick(item)"
                [ngClass]="{'surface-100 text-primary': currMenuItem() === item}"
                styleClass="flex w-12 align-items-center cursor-pointer p-3 hover:text-primary hover:surface-100 transition-duration-150">
                <i class="{{ item.icon }}" [class.mr-2]="!collapseMenu"></i>
                <span class="font-medium" [hidden]="collapseMenu">{{ item.label }}</span>
              </p-button>
            </li>
            }
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="layout__container">
    <div class="flex flex-row px-4 py-2 bg-white align-items-center">
      <p-button
        class="mr-3"
        styleClass="cursor-pointer"
        [text]="true"
        (onClick)="isCollapsed.set(!collapseMenu)"
        icon="fa-duotone fa-solid fa-bars" />

      <span class="text-3xl font-medium">{{ title.getTitle() }}</span>

      <img class="h-1rem mx-auto" src="/assets/work_better_tag.png" alt="" />

      <img class="h-2rem mr-3" [src]="logoUrl()" alt="" />
      <p-divider layout="vertical"></p-divider>
      <p-button (onClick)="userMenu.toggle($event)" [text]="true" class="cursor-pointer">
        <p-avatar icon="fa-solid fa-user" shape="circle" />
        <div class="flex flex-column align-items-start ml-2">
          <span class="font-semibold">John Doe</span>
        </div>
      </p-button>
      <p-menu #userMenu [model]="userMenuItems" [popup]="true" />
    </div>

    <div class="flex-1 px-4 py-3"><router-outlet></router-outlet></div>

    <div class="flex px-4 py-3 flex-column align-items-center">
      <span> Copyright {{ copyrightYear() }} LUMATRAK, Inc. End-to-End Supply Chain Visibility </span>
      <span> All information is confidential and proprietary to PULSE. </span>
    </div>
  </div>
</div>

<p-toast></p-toast>
<p-confirmDialog />
